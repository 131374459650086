import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

import pages from './routes/pages'


Vue.use(VueRouter)

const router = new VueRouter({
  //mode: 'history',
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...pages,
    { 
      path: '/', redirect: { name: 'play' } 
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/games',
      name: 'games',
      component: () => import('@/views/games/Games.vue'),
    },
    {
      path: '/games2',
      name: 'games2',
      component: () => import('@/views/games2/Games2.vue'),
    },
    {
      path: '/games3',
      name: 'games3',
      component: () => import('@/views/games2/Games3.vue'),
    },
    {
      path: '/play',
      name: 'play',
      component: () => import('@/views/games/Play.vue'),
    },
    {
      path: '/debug',
      name: 'debug',
      component: () => import('@/views/debug/Games.vue'),
    },
    {
      path: '/debug2',
      name: 'debug2',
      component: () => import('@/views/debug2/Games2.vue'),
    },
    {
      path: '/debug3',
      name: 'debug3',
      component: () => import('@/views/debug2/Games3.vue'),
    }
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!isLoggedIn) {
    console.log("A :" + to.name);
    if( to.name != 'play') {
      if( to.name != 'misc-not-authorized' ) next({ name: 'misc-not-authorized' });
    } else {
      console.log("B :" + to.query.access_token);
      if( !to.query.access_token ) next({ name: 'misc-not-authorized' });
    }
  }

  //if (!canNavigate(to)) {
    // If logged in => not authorized
    //return next({ name: 'misc-not-authorized' })
  //}

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    //const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    //next(getHomeRouteForLoggedInUser(null));
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router