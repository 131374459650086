// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {}

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {}

// APP CONFIG
export const $themeConfig = {
  app: {
    appName: '', // Will update name in navigation menu (Branding)
    // eslint-disable-next-line global-require
    appLogoImage: require('@/assets/images/logo/logo.svg'), // Will update logo in navigation menu (Branding)
    megalineLogo: require('@/assets/images/logo/megaline.png'),   // 메가
    honeyLogo: require('@/assets/images/logo/honey.png'),
    demoLogo: require('@/assets/images/logo/demo.png'),

    madeLogo: require('@/assets/images/logo/made.png'),
    eagleLogo: require('@/assets/images/logo/eagle.png'),
    jumpLogo: require('@/assets/images/logo/jump.png'),
    bingoLogo: require('@/assets/images/logo/bingo.png'),
    aromaLogo: require('@/assets/images/logo/aroma.png'),
    assaLogo: require('@/assets/images/logo/assa.png'),
    bbrLogo: require('@/assets/images/logo/bbr.png'),
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: 'horizontal', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      hidden: true, 
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'primary', // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static', // static, sticky, hidden
    },
    customizer: true,
    enableScrollToTop: true,
  },
}
